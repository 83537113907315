import { PureComponent } from 'react';

import CheckoutSuccess from 'Component/CheckoutSuccess/CheckoutSuccess.component';
import ContentWrapper from 'Component/ContentWrapper';
import Icons from 'Component/Icons';
import { IconName } from 'Component/Icons/Icons.type';
import TypographyHeader from 'Component/TypographyHeader';
import { Variant } from 'Component/TypographyHeader/TypographyHeader.type';
import { ReactElement } from 'Type/Common.type';
import history from 'Util/History';

import { MakeCommercePaymentStatusComponentProps } from './MakeCommercePaymentStatus.type';

import './MakeCommercePaymentStatus.style';

/** @namespace PlugAndSell2/Route/MakeCommercePaymentStatus/Component */
export class MakeCommercePaymentStatus extends PureComponent<MakeCommercePaymentStatusComponentProps> {
    renderPaymentStatus(): ReactElement {
        const paths = history.location.pathname.split('/');
        const lastPath: string = paths[paths.length - 1] || paths[paths.length - 2] || '';
        const status = lastPath || 'unknown';

        const STATUS_TO_TRANS: Record<string, string> = {
            success: __('SUCCESS'),
            failure: __('ERROR'),
            cancel: __('Canceled'),
        };

        return (
            <TypographyHeader tag="h3" variant={Variant.MEDIUM} mix={{ block: 'MakeCommercePaymentStatus', elem: 'PaymentStatus', mods: { status } }}>
                {__('Payment Status')}: <span>{STATUS_TO_TRANS[status] || __('Unknown')}</span>
            </TypographyHeader>
        );
    }

    render() {
        const { orderId } = this.props;

        return (
            <ContentWrapper mix={{ block: 'MakeCommercePaymentStatus' }}>
                <div block="MakeCommercePaymentStatus" elem="IconWrapper">
                    <Icons name={IconName.CHECK} fill="#56A16B" width="88" height="88" />
                </div>
                {this.renderPaymentStatus()}
                <CheckoutSuccess
                    order={{ order_id: orderId }}
                    orderBillingAddress={{}}
                    orderShippingInfo={{}}
                    orderPaymentInfo={{}}
                    isEmailAvailable={false}
                    email="xxx@xxx.com"
                    firstName="xxx"
                    lastName="yyyy"
                    disableRedirectToMain
                />
            </ContentWrapper>
        );
    }
}

export default MakeCommercePaymentStatus;
