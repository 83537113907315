import { AnyAction } from 'redux';

import { FormattedStore } from 'Component/StoreSwitcher/StoreSwitcher.type';
import { CartDisplayConfig } from 'Query/Cart.type';
import { CheckoutAgreement, Currencies, CurrencyData, PriceTaxDisplay, StoreConfig } from 'Query/Config.type';
import { Country } from 'Query/Region.type';
import { ReviewRatingItem } from 'Query/Review.type';
import { Device } from 'Type/Device.type';

export enum ConfigActionType {
    UPDATE_CONFIG = 'UPDATE_CONFIG',
    UPDATE_CONFIG_DEVICE = 'UPDATE_CONFIG_DEVICE',
    UPDATE_CURRENT_CURRENCY = 'UPDATE_CURRENT_CURRENCY',
}

export interface UpdateConfigAction extends AnyAction {
    type: ConfigActionType.UPDATE_CONFIG;
    config: Partial<ConfigStore>;
}

export interface UpdateConfigDeviceAction extends AnyAction {
    type: ConfigActionType.UPDATE_CONFIG_DEVICE;
    device: Device;
}

export interface UpdateConfigCurrencyAction extends AnyAction {
    type: ConfigActionType.UPDATE_CURRENT_CURRENCY;
    selectedCurrency: string;
}

export type ConfigAction = UpdateConfigAction | UpdateConfigDeviceAction | UpdateConfigCurrencyAction;

export interface ReviewRatings {
    items: ReviewRatingItem[];
}

export type ConfigStore = StoreConfig & {
    countries: Country[];
    reviewRatings: ReviewRatingItem[];
    checkoutAgreements: CheckoutAgreement[];
    currencyData: CurrencyData;
    cartDisplayConfig: CartDisplayConfig;
    priceTaxDisplay: PriceTaxDisplay;
    isLoading: boolean;
    category_url_suffix: string;
    device: Device;
    currency: Currencies;
    storeConfig: StoreConfig;
    storeList: FormattedStore[] | [];
    product_url_suffix: string;
    city: string;
    street_line1: string;
    street_line2: string;
    email: string;
    phone: string;
    postcode: string;
};

declare module 'Util/Store/Store.type' {
    export interface RootState {
        ConfigReducer: ConfigStore;
    }
}
