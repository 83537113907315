/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */

// TODO: implement props passing

import { Subscribe } from 'unstated-typescript';

import SharedTransition from './SharedTransition.component';
import SharedTransitionContainer from './SharedTransition.unstated';

/** @namespace PlugAndSell2/Component/SharedTransition/Container/SharedTransitionWrapper */
export function SharedTransitionWrapper(props: Record<string, any>): JSX.Element {
    return <Subscribe to={[SharedTransitionContainer]}>{(sharedTransition) => <SharedTransition {...props} {...sharedTransition} />}</Subscribe>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default SharedTransitionWrapper;
