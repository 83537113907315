import { withStoreRegex } from "Component/Router/Router.component";
import { RouterItemType, RouterSwitchItemType } from "Component/Router/Router.config";
import { RouterItem } from "Component/Router/Router.type";
import { lazy } from "react";

import { Route } from "react-router-dom";

export const CreateAccountPage = lazy(() => import(
  /* webpackMode: "lazy", webpackChunkName: "account" */ 
  'Route/CreateAccount'
  )
);

export const switchItemsProperty = (originalMember: RouterItem[]) => {
  return [
    ...originalMember,
    {
        // @ts-ignore
        component: <Route path={withStoreRegex('/customer/createB2B')} render={() => <CreateAccountPage isB2B showTaxVatNumber />} />,
        position: 62,
        name: 'CREATE_ACCOUNT_B2B',
    },
  ];
}

export default {
  'PlugAndSell2/Component/Router/Component': {
    'member-property': {
      [RouterItemType.SWITCH_ITEMS_TYPE]: switchItemsProperty,
    }
  }
}