import media from 'Util/Media';
import { LOGO_MEDIA } from 'Util/Media/Media';
import { getPageType } from '../util/Page';
import { GQLUrlRewriteEntityTypeEnum } from 'Type/Graphql.type';

const appendOpenGraphTags = (args: any, callback: Function, ctx: { _generateMetaFromMetadata: Function, getOpenGraphsTags: Function }) => {
  const meta = callback(...args);
  const opengraph = ctx._generateMetaFromMetadata(
    ctx.getOpenGraphsTags(),
    'property'
  );

  return [...meta, ...opengraph];
};

const getOpenGraphsTags = (_args: any, _callback: any, ctx: { _getDescription: Function, _getTitle: Function, getImage: Function }) => {
  return {
    'og:url': window.location.href,
    'og:description': ctx._getDescription(),
    'og:title': ctx._getTitle(),
    'og:image': ctx.getImage(),
    'og:type': 'website',
    'twitter:card': 'summary_large_image',
    'twitter:domain': window.location.host,
    'twitter:url': window.location.href,
    'twitter:title': ctx._getTitle(),
    'twitter:description': ctx._getDescription(),
    'twitter:image': ctx.getImage(),
  };
};

const getImage = (_args: any, _callback: any, ctx: { props: { image: string, default_image: string }}) => {
  const pageType = getPageType();

  if (ctx.props?.image) {
    switch (pageType) {
      case GQLUrlRewriteEntityTypeEnum.PRODUCT:
        return ctx.props?.image
      case GQLUrlRewriteEntityTypeEnum.MF_BLOG_POST:
        return ctx.props?.image
      default:
        return media(ctx.props?.default_image, LOGO_MEDIA);
    }
  }
  
  return media(ctx.props?.default_image, LOGO_MEDIA);
};

const appendImageToMapStateToProps = (args: any[], callback: Function) => {
  const initialState = callback(...args);
  const state = args[0];

  return {
    ...initialState,
    default_image: state.ConfigReducer.header_logo_src,
    image: state.MetaReducer.image,
  };
};

export default {
  'PlugAndSell2/Component/Meta/Container': {
    'member-function': {
      _getMetadata: appendOpenGraphTags,
      getImage,
      getOpenGraphsTags,
    },
  },
  'PlugAndSell2/Component/Meta/Container/mapStateToProps': {
    function: appendImageToMapStateToProps,
  },
};
