import { Reducer } from 'redux';

import { UrlRewritesAction, UrlRewritesActionType, UrlRewritesStore } from './UrlRewrites.type';

/** @namespace PlugAndSell2/Store/UrlRewrites/Reducer/getInitialState */
export const getInitialState = (): UrlRewritesStore => ({
    urlRewrite: {},
    isLoading: false,
    requestedUrl: window.isPrefetchValueUsed ? location.pathname : '',
});

/** @namespace PlugAndSell2/Store/UrlRewrites/Reducer/UrlRewritesReducer */
export const UrlRewritesReducer: Reducer<UrlRewritesStore, UrlRewritesAction> = (state = getInitialState(), action) => {
    switch (action.type) {
        case UrlRewritesActionType.UPDATE_URL_REWRITE:
            const { urlRewrite, requestedUrl } = action;

            return {
                ...state,
                isLoading: false,
                urlRewrite,
                requestedUrl,
            };

        case UrlRewritesActionType.IS_LOADING_URL_REWRITE:
            const { isLoading } = action;

            return {
                ...state,
                isLoading,
            };
        default:
            return state;
    }
};

export default UrlRewritesReducer;
