import { Reducer } from 'redux';

import { BreadcrumbsAction, BreadcrumbsActionType, BreadcrumbsStore } from './Breadcrumbs.type';

/** @namespace PlugAndSell2/Store/Breadcrumbs/Reducer/getInitialState */
export const getInitialState = (): BreadcrumbsStore => ({
    breadcrumbs: [],
    areBreadcrumbsVisible: true,
});

/** @namespace PlugAndSell2/Store/Breadcrumbs/Reducer/BreadcrumbsReducer */
export const BreadcrumbsReducer: Reducer<BreadcrumbsStore, BreadcrumbsAction> = (state = getInitialState(), action) => {
    switch (action.type) {
        case BreadcrumbsActionType.UPDATE_BREADCRUMBS:
            const { breadcrumbs = [] } = action;

            return {
                ...state,
                breadcrumbs,
            };

        case BreadcrumbsActionType.TOGGLE_BREADCRUMBS:
            const { areBreadcrumbsVisible = false } = action;

            return {
                ...state,
                areBreadcrumbsVisible,
            };

        default:
            return state;
    }
};

export default BreadcrumbsReducer;
