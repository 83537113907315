import { PureComponent } from 'react';

import CheckoutSuccess from 'Component/CheckoutSuccess/CheckoutSuccess.component';
import ContentWrapper from 'Component/ContentWrapper';
import Icons from 'Component/Icons';
import { IconName } from 'Component/Icons/Icons.type';
import TypographyHeader from 'Component/TypographyHeader';
import { Variant } from 'Component/TypographyHeader/TypographyHeader.type';
import { ReactElement } from 'Type/Common.type';
import history from 'Util/History';
import { getQueryParam } from 'Util/Url';

import { KevinPaymentStatusComponentProps } from './KevinPaymentStatus.type';

import './KevinPaymentStatus.style';

/** @namespace PlugAndSell2/Route/KevinPaymentStatus/Component */
export class KevinPaymentStatus extends PureComponent<KevinPaymentStatusComponentProps> {
    renderPaymentStatus(): ReactElement {
        const status = getQueryParam('msg', history.location);

        return (
            <TypographyHeader
                tag="h3"
                variant={Variant.MEDIUM}
                mix={{ block: 'KevinPaymentStatus', elem: 'PaymentStatus', mods: { status: status || 'unknown' } }}
            >
                {__('Payment Status')}: <span>{status || __('Unknown')}</span>
            </TypographyHeader>
        );
    }

    render() {
        const { orderId } = this.props;

        return (
            <ContentWrapper mix={{ block: 'KevinPaymentStatus' }}>
                <div block="KevinPaymentStatus" elem="IconWrapper">
                    <Icons name={IconName.CHECK} fill="#56A16B" width="88" height="88" />
                </div>
                {this.renderPaymentStatus()}
                <CheckoutSuccess
                    order={{ order_id: orderId }}
                    orderBillingAddress={{}}
                    orderShippingInfo={{}}
                    orderPaymentInfo={{}}
                    isEmailAvailable={false}
                    email="xxx@xxx.com"
                    firstName="xxx"
                    lastName="yyyy"
                    disableRedirectToMain
                />
            </ContentWrapper>
        );
    }
}

export default KevinPaymentStatus;
