import { ContractorsReducerStore } from "../../../contractors/src/store/Contractors/Contractors.type";
import { ContractorsActionType } from "../store/Contractors/Contractors.type";
import { AnyAction } from 'redux';

export const aroundCreateContractorReducer = (args: [state: ContractorsReducerStore | undefined, action: AnyAction], callback: Function, context: Function) => {
  const [, action] = args;
  const originalUpdatedState = callback.apply(context, args);

  if (!action) {
      return originalUpdatedState;
  }

  const { type } = action;

  if (type === ContractorsActionType.UPDATE_MERCHANT_LIMIT) {
      return { ...originalUpdatedState, merchantLimit: action.payload };
  }

  return originalUpdatedState;
}

export default {
    'ContractorsPlugin/Store/Contractors/Reducer/ContractorsReducer': {
        function: aroundCreateContractorReducer,
    },
};
