import { AnyAction } from 'redux';

import { ContractorsContractorReducerStore } from '../../../../contractors-contractor/src/store/Contractors/Contractors.type'
import { ContractorsMerchantLimitReducerStore } from "../../../../contractors-merchant-limit/src/store/Contractors/Contractors.type"

export enum ContractorsActionType {
  UPDATE_LOADING = 'UPDATE_LOADING',
}
export interface UpdateLoadingAction extends AnyAction {
  type: ContractorsActionType.UPDATE_LOADING;
  payload: boolean;
}

export type ContractorsAction = UpdateLoadingAction

export interface ContractorsBaseStore {
  isLoading: boolean
}

export type ContractorsReducerStore = ContractorsBaseStore & ContractorsContractorReducerStore & ContractorsMerchantLimitReducerStore;
