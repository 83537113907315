import { Reducer } from 'redux';

import { CheckoutAction, CheckoutActionType, CheckoutStore } from './Checkout.type';

/** @namespace PlugAndSell2/Store/Checkout/Reducer/getInitialState */
export const getInitialState = (): CheckoutStore => ({
    shippingFields: {},
    email: '',
    isEmailAvailable: true,
    isOrderButtonEnabled: true,
    kevinSelectedBank: null,
    kevinBanksList: null,
});

/** @namespace PlugAndSell2/Store/Checkout/Reducer/CheckoutReducer */
export const CheckoutReducer: Reducer<CheckoutStore, CheckoutAction> = (state = getInitialState(), action) => {
    switch (action.type) {
        case CheckoutActionType.UPDATE_SHIPPING_FIELDS:
            const { shippingFields } = action;

            return {
                ...state,
                shippingFields,
            };

        case CheckoutActionType.UPDATE_EMAIL:
            const { email } = action;

            return {
                ...state,
                email,
            };

        case CheckoutActionType.UPDATE_EMAIL_AVAILABLE:
            const { isEmailAvailable } = action;

            return {
                ...state,
                isEmailAvailable,
            };

        case CheckoutActionType.UPDATE_KEVIN_BANKS_LIST:
            const { banks } = action;

            return {
                ...state,
                kevinBanksList: banks,
            };

        case CheckoutActionType.UPDATE_KEVIN_SELECTED_BANK:
            const { bank } = action;

            return {
                ...state,
                kevinSelectedBank: bank,
            };
        case CheckoutActionType.UPDATE_ORDER_BUTTON_ENABLE_STATUS:
            const { isOrderButtonEnabled } = action;

            return {
                ...state,
                isOrderButtonEnabled,
            };
        default:
            return state;
    }
};

export default CheckoutReducer;
