import { ProductType } from 'Component/Product/Product.config';

import { NOT_APPLICABLE } from '../component/GoogleTagManager/GoogleTagManager.config';
import { getBrand, getCategories, getPrice, getProductName, getVariant } from './baseProduct.data';

/** @namespace GtmNew/EventData/ActionProduct/Data/getDimensionOne */
export const getDimensionOne = (type_id, product, configurableVariantIndex, mainSku) => {
    if (type_id === ProductType.simple) {
        return NOT_APPLICABLE;
    }

    if (!configurableVariantIndex && mainSku && type_id === ProductType.configurable) {
        return name;
    }

    if (!isNaN(configurableVariantIndex) && configurableVariantIndex !== false && product.variants[configurableVariantIndex]) {
        const { name = '' } = product.variants[configurableVariantIndex];

        return name;
    }

    return NOT_APPLICABLE;
};

/** @namespace GtmNew/EventData/ActionProduct/Data/getDimensionTwo */
export const getDimensionTwo = (type_id, product, configurableVariantIndex, inQtyChange = false, mainSku) => {
    if (type_id === ProductType.simple) {
        return NOT_APPLICABLE;
    }

    if (!isNaN(configurableVariantIndex) && configurableVariantIndex !== false && product.variants[configurableVariantIndex]) {
        if (inQtyChange) {
            return mainSku;
        }
        const { sku } = product.variants[configurableVariantIndex];

        return sku;
    }

    return NOT_APPLICABLE;
};

/** @namespace GtmNew/EventData/ActionProduct/Data/productAddToCart */
export const productAddToCart = ({ product, quantity = 0, configurableVariantIndex = false }) => {
    const { type_id = ProductType.SIMPLE, categories, sku, price = false } = product;

    return {
        name: getProductName(type_id, product),
        id: sku,
        price: getPrice(product, type_id, price),
        category: getCategories(categories),
        brand: getBrand(product),
        variant: getVariant(product, type_id, configurableVariantIndex),
        quantity,
        dimension1: getDimensionOne(type_id, product, configurableVariantIndex),
        dimension2: getDimensionTwo(type_id, product, configurableVariantIndex),
    };
};

/** @namespace GtmNew/EventData/ActionProduct/Data/productQtyChangeData */
export const productQtyChangeData = ({ product: item, configurableVariantIndex = false }) => {
    const {
        qty = false,
        price,
        sku: mainSku = '',
        product: { type_id = ProductType.simple, categories, sku: parentSku, name: parentName, variants },
        product,
    } = item;

    return {
        name: parentName,
        id: parentSku,
        price: type_id === ProductType.configurable ? getPrice(variants[0], type_id, price, false) : getPrice(product, type_id, price, false),
        category: getCategories(categories),
        brand: getBrand(product),
        variant: getVariant(product, type_id, configurableVariantIndex),
        quantity: Math.abs(qty),
        dimension1: getDimensionOne(type_id, product, configurableVariantIndex, mainSku),
        dimension2: getDimensionTwo(type_id, product, configurableVariantIndex, true, mainSku),
    };
};
