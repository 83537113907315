import { OfflineActionType, SetBigOfflineNoticeAction, ShowOfflineNoticeAction } from './Offline.type';

/**
 * Show offline notice.
 * @param  {boolean} msgType
 * @return {void}
 * @namespace PlugAndSell2/Store/Offline/Action/showOfflineNotice */
export const showOfflineNotice = (isOffline: boolean): ShowOfflineNoticeAction => ({
    type: OfflineActionType.SHOW_OFFLINE_NOTICE,
    isOffline,
});

/**
 * Set offline notice size to big.
 * @param  {boolean} isBig
 * @return {void}
 * @namespace PlugAndSell2/Store/Offline/Action/setBigOfflineNotice */
export const setBigOfflineNotice = (isBig: boolean): SetBigOfflineNoticeAction => ({
    type: OfflineActionType.SET_BIG_OFFLINE_NOTICE,
    isBig,
});
