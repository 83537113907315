import { AnyAction } from 'redux';
import { ContractorMerchantLimit } from "../../query/Contractors/Contractors.type";
import { ContractorsReducerStore } from 'packages/contractors/src/store/Contractors/Contractors.type';

export enum ContractorsActionType {
  UPDATE_MERCHANT_LIMIT = 'UPDATE_MERCHANT_LIMIT',
}

export interface UpdateMerchantLimitAction extends AnyAction {
  type: ContractorsActionType.UPDATE_MERCHANT_LIMIT;
  payload: ContractorMerchantLimit;
}

export interface ContractorsMerchantLimitReducerStore {
  merchantLimit: ContractorMerchantLimit | null;
}

export type ContractorsDispatcherData = {
  merchant_limit: ContractorMerchantLimit
}

declare module 'Util/Store/Store.type' {
  export interface RootState {
    ContractorsReducer: ContractorsReducerStore;
  }
}
