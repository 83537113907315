import { AnyAction } from 'redux';
import { ContractorForLoggedInUser } from "../../query/Contractors/Contractors.type";
import { ContractorsReducerStore } from '../../../../contractors/src/store/Contractors/Contractors.type';
import { ContractorOutput } from '../../../../contractors/src/query/Contractors/Contractors.type';

export enum ContractorsActionType {
  UPDATE_CONTRACTOR_FOR_LOGGED_IN_USER = 'UPDATE_CONTRACTOR_FOR_LOGGED_IN_USER',
}

export interface UpdateContractorForLoggedInUserAction extends AnyAction {
  type: ContractorsActionType.UPDATE_CONTRACTOR_FOR_LOGGED_IN_USER;
  payload: ContractorForLoggedInUser;
}

export interface ContractorsContractorReducerStore {
  contractorForLoggedInUser: ContractorForLoggedInUser | null;
  contractor?: Partial<ContractorOutput> | null;
}

export type ContractorsDispatcherData =  { getContractorForLoggedInCustomer: ContractorOutput }

declare module 'Util/Store/Store.type' {
  export interface RootState {
    ContractorsReducer: ContractorsReducerStore;
  }
}
