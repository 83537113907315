import { PureComponent } from 'react';

import KevinPaymentStatus from './KevinPaymentStatus.component';
import { KevinPaymentStatusComponentProps, KevinPaymentStatusContainerProps, KevinPaymentStatusContainerPropsKeys } from './KevinPaymentStatus.type';

/** @namespace PlugAndSell2/Route/KevinPaymentStatus/Container */
export class KevinPaymentStatusContainer extends PureComponent<KevinPaymentStatusContainerProps> {
    componentWillUnmount(): void {
        localStorage.removeItem('selectedPaymentCode');
    }

    containerProps(): Pick<KevinPaymentStatusComponentProps, KevinPaymentStatusContainerPropsKeys> {
        const orderId = localStorage.getItem('last_order_id') || '';

        return {
            orderId,
        };
    }

    render() {
        return <KevinPaymentStatus {...this.containerProps()} />;
    }
}

export default KevinPaymentStatusContainer;
