export const SET_ANNOUNCEMENT = 'SET_ANNOUNCEMENT';
export const SET_ANNOUNCEMENT_BACKGROUND_COLOR = 'SET_ANNOUNCEMENT_BACKGROUND_COLOR';
export const SET_CURRENT_LOCATION = 'SET_CURRENT_LOCATION';

export const IS_DEMO_VISIBLE = 'isDemoVisible';
export const ANNOUNCEMENT_NOTICE = 'ANNOUNCEMENT_NOTICE';

/** @namespace Domneo/Announcement/Store/Announcement/Action/setAnnouncement */
export const setAnnouncement = (announcement) => ({
    type: SET_ANNOUNCEMENT,
    announcement,
});

/** @namespace Domneo/Announcement/Store/Announcement/Action/setAnnouncementBackgroundColor */
export const setAnnouncementBackgroundColor = (announcementBackgroundColor) => ({
    type: SET_ANNOUNCEMENT_BACKGROUND_COLOR,
    announcementBackgroundColor,
});

/** @namespace Domneo/Announcement/Store/Announcement/Action/setCurrentLocation */
export const setCurrentLocation = (currentLocation) => ({
    type: SET_CURRENT_LOCATION,
    currentLocation,
});
