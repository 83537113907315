import { GQLUrlRewriteEntityTypeEnum } from "Type/Graphql.type";
import getStore from "Util/Store";

export const URL_REWRITE = 'url-rewrite';

export const getPageType = (): GQLUrlRewriteEntityTypeEnum | '' => {
  const { getState } = getStore()
  const { UrlRewritesReducer: { urlRewrite: { type } } } = getState()

  return type || ''
};
