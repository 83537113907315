import { PureComponent } from 'react';

import Button from 'Component/Button';
import { ButtonVariant } from 'Component/Button/Button.type';
import CheckoutSuccessDetails from 'Component/CheckoutSuccessDetails';
import CheckoutSuccessProduct from 'Component/CheckoutSuccessProduct';
import { CheckoutSuccessTotals } from 'Component/CheckoutSuccessTotals';
import Link from 'Component/Link';
import { ORDER_ID } from 'Component/MyAccountOrder/MyAccountOrder.config';
import { AccountPageUrl } from 'Route/MyAccount/MyAccount.config';
import { CartTotals } from 'Store/Cart/Cart.type';
import { ReactElement } from 'Type/Common.type';
import { GQLCurrencyEnum } from 'Type/Graphql.type';
import { isSignedIn } from 'Util/Auth/IsSignedIn';
import { appendWithStoreCode } from 'Util/Url';

import { CheckoutSuccessComponentProps } from './CheckoutSuccess.type';

import './CheckoutSuccess.style';

/** @namespace PlugAndSell2/Component/CheckoutSuccess/Component */
export class CheckoutSuccessComponent extends PureComponent<CheckoutSuccessComponentProps> {
    renderButtons(): ReactElement {
        return (
            <div block="CheckoutSuccess" elem="ButtonWrapper">
                <Link block="Button" mix={{ block: 'CheckoutSuccess', elem: 'ContinueButton' }} to="/">
                    {__('Continue shopping')}
                </Link>
            </div>
        );
    }

    renderProducts(): ReactElement {
        let cartTotals;

        try {
            cartTotals = JSON.parse(sessionStorage.getItem('last_order_totals') || '') as CartTotals;
        } catch {
            cartTotals = null;
        }

        if (!cartTotals?.items) {
            return null;
        }

        return cartTotals.items.map(({ prices, quantity, product: { name, thumbnail } }) => (
            <CheckoutSuccessProduct
                key={name}
                name={String(name)}
                qty={quantity}
                rowTotal={prices.row_total.value}
                image={thumbnail}
                currencyCode={prices.price.currency}
                originalPrice={prices.price.value || NaN}
                unitPrice={prices.price.value || NaN}
            />
        ));
    }

    renderProductsInfo(): ReactElement {
        return (
            <div block="CheckoutSuccess" elem="Products-Info">
                <p>{__('Product')}</p>
                <p>{__('Price per unit')}</p>
                <p>{__('Qty')}</p>
                <p>{__('Cost')}</p>
            </div>
        );
    }

    renderProductsInfoMobile() {
        return (
            <div block="CheckoutSuccess" elem="Products-InfoMobile">
                <p>{__('Products summary')}</p>
            </div>
        );
    }

    renderProductsSection(): ReactElement {
        return (
            <div block="CheckoutSuccess" elem="ProductsSection">
                {this.renderProductsInfo()}
                {this.renderProductsInfoMobile()}
                <div block="CheckoutSuccess" elem="Products">
                    {this.renderProducts()}
                </div>
            </div>
        );
    }

    renderDetailsSection(): ReactElement {
        const { orderBillingAddress, orderShippingInfo } = this.props;

        return (
            <CheckoutSuccessDetails
                billingAddress={{
                    firstname: orderBillingAddress.firstname,
                    lastname: orderBillingAddress.lastname,
                    street: [orderBillingAddress.street || ''],
                    city: orderBillingAddress.city,
                    postcode: orderBillingAddress.postcode,
                    telephone: orderBillingAddress.telephone,
                }}
                shippingAddress={orderShippingInfo.shipping_address}
            />
        );
    }

    renderGoToPaymentSection(): ReactElement {
        const {
            redirectUrl: url,
            order: { ca_raty_form },
        } = this.props;

        if (!url && !ca_raty_form) {
            return null;
        }

        return (
            <div block="CheckoutSuccess" elem="PaymentRedirect">
                {url ? (
                    <div block="CheckoutSuccess" elem="PaymentRedirectText">
                        {__('You will be automatically redirected to payment in 5 seconds. If not, click on the button below.')}
                    </div>
                ) : null}
                <div block="CheckoutSuccess" elem="PaymentRedirectButton">
                    {/* eslint-disable-next-line @typescript-eslint/naming-convention */}
                    {ca_raty_form ? <div block="CheckoutSuccess" elem="PaymentCAText" dangerouslySetInnerHTML={{ __html: ca_raty_form }} /> : null}
                    {url ? (
                        <Button asLink to={url} variant={ButtonVariant.PRIMARY}>
                            {__('Make a payment')}
                        </Button>
                    ) : null}
                </div>
            </div>
        );
    }

    renderCreateAccountButton(): ReactElement {
        const { isEmailAvailable, email, firstName, lastName, order } = this.props;

        if (!isEmailAvailable || isSignedIn()) {
            return null;
        }

        sessionStorage.setItem(ORDER_ID, JSON.stringify(order));

        return (
            <div block="CheckoutRegistrationLink">
                <p>{__('You can track your order status by creating an account.')}</p>
                <p>{`${__('Email address')}: ${email}`}</p>
                <Link
                    to={{
                        pathname: appendWithStoreCode(`${AccountPageUrl.REGISTRATION_URL}`),
                        state: {
                            firstName,
                            lastName,
                            email,
                            order,
                        },
                    }}
                    block="Button"
                >
                    {__('Create account')}
                </Link>
            </div>
        );
    }

    renderTotals(): ReactElement {
        const { orderShippingInfo } = this.props;

        let cartTotals;

        try {
            cartTotals = JSON.parse(sessionStorage.getItem('last_order_totals') || '') as unknown as CartTotals;
        } catch {
            cartTotals = null;
        }

        if (!cartTotals) {
            return;
        }

        return (
            <div block="CheckoutSuccess" elem="Totals">
                <CheckoutSuccessTotals
                    totals={{
                        grandTotal: cartTotals.prices?.grand_total,
                        subTotal: cartTotals.prices?.subtotal_including_tax,
                        shippingGross: {
                            value: orderShippingInfo?.shipping_incl_tax || NaN,
                            currency: cartTotals.prices?.grand_total?.currency || GQLCurrencyEnum.USD,
                        },
                    }}
                />
            </div>
        );
    }

    render(): ReactElement {
        const {
            order: { order_id },
            isPaymentRedirect,
        } = this.props;

        return (
            <div block="CheckoutSuccess">
                {isPaymentRedirect && <h3>{__('You will be redirected to the payment system...')}</h3>}
                <h3>
                    {__('Your order is:')}
                    <span block="CheckoutSuccess" elem="OrderNumber">
                        {order_id}
                    </span>
                </h3>
                <p>{__("We'll send you an email with order confirmation with and tracking info.")}</p>
                <p>{__("We'll send you an email about next checkout steps also")}</p>
                {this.renderGoToPaymentSection()}
                {this.renderProductsSection()}
                {this.renderTotals()}
                {this.renderDetailsSection()}
            </div>
        );
    }
}

export default CheckoutSuccessComponent;
