/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const CLEAR_AUTHOR = 'CLEAR_AUTHOR';
export const UPDATE_AUTHOR = 'UPDATE_AUTHOR';
export const NO_AUTHORS = 'NO_AUTHORS';

/**
 * Update categories
 * @param categories
 * @returns {{categories: *, type: string}}
 * @namespace PlugAndSell/Blog/Store/Author/Action/updateAuthor */
export const updateAuthor = (author) => ({
    type: UPDATE_AUTHOR,
    isLoaded: true,
    author,
});

/**
 * Update categories
 * @param categories
 * @returns {{categories: *, type: string}}
 * @namespace PlugAndSell/Blog/Store/Author/Action/clearAuthor */
export const clearAuthor = () => ({
    type: CLEAR_AUTHOR,
});

/**
 * @param message
 * @returns {{type: string, message: *}}
 * @namespace PlugAndSell/Blog/Store/Author/Action/authorException */
export const authorException = (message) => ({
    type: NO_AUTHORS,
    message,
});
