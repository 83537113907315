import { ProductType } from 'Component/Product/Product.config';
import { roundPrice } from 'Util/Price';

import { CATEOGRY_LIMIT, CHECKOUT, GROUPED, NOT_APPLICABLE, ZERO } from '../component/GoogleTagManager/GoogleTagManager.config';

/** @namespace GtmNew/EventData/BaseProduct/Data/getProductName */
export const getProductName = (type, product, mainSku = '', click) => {
    if (!product.name) {
        return null;
    }
    const { name = '' } = product;

    if (click) {
        return NOT_APPLICABLE;
    }

    if (type === ProductType.simple) {
        return name;
    }

    if (type === ProductType.configurable && mainSku) {
        return name;
    }

    return name;
};

/** @namespace GtmNew/EventData/BaseProduct/Data/getProductSKU */
export const getProductSKU = (type, product, mainSku = '', click) => {
    const { sku = '' } = product;

    if (click) {
        return NOT_APPLICABLE;
    }

    if (type === ProductType.simple) {
        return sku;
    }

    if (type === ProductType.configurable && mainSku) {
        const { variants } = product;

        return variants.find(({ sku }) => sku === mainSku).sku;
    }

    return sku;
};

/** @namespace GtmNew/EventData/BaseProduct/Data/getCategories */
export const getCategories = (categories = []) => {
    if (!Array.isArray(categories)) {
        return [];
    }

    const limitedCategories = categories.slice(0, CATEOGRY_LIMIT).map(({ name }) => name);
    const categoryString = limitedCategories.join('/');

    return categoryString.charAt(0) === '/' ? categoryString.substring(1) : categoryString;
};

/** @namespace GtmNew/EventData/BaseProduct/Data/getQty */
export const getQty = (qty) => {
    if (qty) {
        return { quantity: qty };
    }

    return null;
};

/** @namespace GtmNew/EventData/BaseProduct/Data/getVariantIndex */
export const getVariantIndex = (type_id, variants = [], configurableVariantIndex) => {
    if (type_id === ProductType.simple) {
        return -1;
    }

    if (!isNaN(configurableVariantIndex) && configurableVariantIndex >= 0 && configurableVariantIndex !== false) {
        return configurableVariantIndex;
    }

    if (variants[0] !== undefined) {
        return 0;
    }

    return -1;
};

/** @namespace GtmNew/EventData/BaseProduct/Data/getPrice */
export const getPrice = (variant, type_id, price, notInGetProduct = true) => {
    if (price && notInGetProduct) {
        return price;
    }

    if (!variant.price_range) {
        return;
    }

    const { price_range: { minimum_price: { final_price: { value: discountValue = null } = {}, regular_price: { value = 0 } = {} } } = {} } = variant;

    return type_id !== GROUPED ? +roundPrice(discountValue || value) || 0 : 0;
};

/** @namespace GtmNew/EventData/BaseProduct/Data/getBrand */
export const getBrand = (product) => {
    const { attributes: { manufacturer } = {} } = product;

    if (manufacturer === undefined) {
        return NOT_APPLICABLE;
    }

    const { attribute_options, attribute_value } = manufacturer;

    const { label } = attribute_options[attribute_value] || {};

    return label;
};

/** @namespace GtmNew/EventData/BaseProduct/Data/getVariant */
export const getVariant = (product, type_id, configurableVariantIndex = false) => {
    const {
        variants = [],
        attributes: { size },
    } = product;

    if (size === undefined || type_id === ProductType.simple || variants[0] === undefined) {
        return NOT_APPLICABLE;
    }

    const configIndex = !configurableVariantIndex ? 0 : configurableVariantIndex;

    const { attribute_options } = size;

    const {
        attributes: {
            size: { attribute_value },
        },
    } = variants[configIndex];

    if (!attribute_options[attribute_value]) {
        return null;
    }

    const {
        swatch_data: { value },
    } = attribute_options[attribute_value];

    return value;
};

/** @namespace GtmNew/EventData/BaseProduct/Data/getPosition */
export const getPosition = (position, click) => {
    if (click && position) {
        return { position };
    }

    return null;
};

/** @namespace GtmNew/EventData/BaseProduct/Data/baseProductData */
export const baseProductData = (item, click = false, notInGetProduct = true, event = '') => {
    const {
        qty = false,
        price,
        sku: mainSku = '',
        product: { type_id = ProductType.simple, categories, sku: parentSku, position = 0, name: parentName, variants = [] },
        product,
    } = item;

    const data = {
        name: parentName,
        id: parentSku,
        price:
            type_id === ProductType.configurable && notInGetProduct === false
                ? getPrice(variants[0], type_id, price, notInGetProduct)
                : getPrice(product, type_id, price, notInGetProduct),
        ...getQty(qty),
        ...getPosition(position, click),
        category: getCategories(categories),
        brand: getBrand(product),
        dimension1: type_id === ProductType.configurable && !click ? mainSku : NOT_APPLICABLE,
        dimension2: type_id === ProductType.configurable ? getProductName(type_id, product, mainSku, click) : NOT_APPLICABLE,
    };

    if (event === CHECKOUT) {
        data.variant = getVariant(product, type_id, ZERO);
    }

    return data;
};
