import { PureComponent } from 'react';

import { ReactElement } from 'Type/Common.type';

import { CheckoutSuccessDetailsComponentProps } from './CheckoutSuccessDetails.type';

import './CheckoutSuccessDetails.style';

/** @namespace PlugAndSell2/Component/CheckoutSuccessDetails/Component */
export class CheckoutSuccessDetailsComponent extends PureComponent<CheckoutSuccessDetailsComponentProps> {
    render(): ReactElement {
        const { billingAddress, shippingAddress } = this.props;
        const {
            firstname: billingFirstname,
            lastname: billingLastname,
            street: billingStreet,
            postcode: billingPostcode,
            city: billingCity,
            telephone: billingTelephone,
        } = billingAddress || {};

        const {
            firstname: shippingFirstname,
            lastname: shippingLastname,
            street: shippingStreet,
            postcode: shippingPostcode,
            city: shippingCity,
            telephone: shippingTelephone,
        } = shippingAddress || {};

        return (
            <div block="CheckoutSuccessDetails" elem="Info">
                {shippingLastname && (
                    <div block="CheckoutSuccessDetails" elem="InfoBox">
                        <p block="CheckoutSuccessDetails" elem="InfoBoxTitle">
                            {__('Client address')}
                        </p>
                        <div block="CheckoutSuccessDetails" elem="InfoBoxContent">
                            <div>
                                <span>{shippingFirstname}</span>
                                <span>{shippingLastname}</span>
                            </div>
                            <div>
                                <span>{shippingStreet}</span>
                            </div>
                            <div>
                                <span>{shippingPostcode}</span>
                                <span>{shippingCity}</span>
                            </div>
                            <div>
                                <span>{shippingTelephone}</span>
                            </div>
                        </div>
                    </div>
                )}
                {billingLastname && (
                    <div block="CheckoutSuccessDetails" elem="InfoBox">
                        <p block="CheckoutSuccessDetails" elem="InfoBoxTitle">
                            {__('Invoice data')}
                        </p>
                        <div block="CheckoutSuccessDetails" elem="InfoBoxContent">
                            <div>
                                <span>{billingFirstname}</span>
                                <span>{billingLastname}</span>
                            </div>
                            <div>
                                <span>{billingStreet}</span>
                            </div>
                            <div>
                                <span>{billingPostcode}</span>
                                <span>{billingCity}</span>
                            </div>
                            <div>
                                <span>{billingTelephone}</span>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default CheckoutSuccessDetailsComponent;
