/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { CLEAR_POSTS, UPDATE_POSTS } from './Posts.action';

export const emptyPosts = {
    items: [],
    count: 0,
};

export const initialState = {
    posts: emptyPosts,
    post: {},
};

/** @namespace PlugAndSell/Blog/Store/Posts/Reducer/PostsReducer */
export const PostsReducer = (state = initialState, action) => {
    const { isLoaded, type } = action;
    switch (type) {
        case UPDATE_POSTS:
            const {
                posts: { items, total_count: count, categoryId },
            } = action;

            return {
                ...state,
                posts: {
                    items,
                    count,
                    categoryId,
                },
                isLoaded,
            };

        case CLEAR_POSTS: {
            return {
                ...state,
                posts: emptyPosts,
                isLoaded,
            };
        }
        default:
            return state;
    }
};

export default PostsReducer;
