import { Reducer } from 'redux';

import { StoreInPickUpAction, StoreInPickUpActionType, StoreInPickUpStore } from './StoreInPickUp.type';

/** @namespace PlugAndSell2/Store/StoreInPickUp/Reducer/getInitialState */
export const getInitialState = (): StoreInPickUpStore => ({
    store: null,
});

/** @namespace PlugAndSell2/Store/StoreInPickUp/Reducer/StoreInPickUpReducer */
export const StoreInPickUpReducer: Reducer<StoreInPickUpStore, StoreInPickUpAction> = (state = getInitialState(), action) => {
    const { type } = action;

    switch (type) {
        case StoreInPickUpActionType.SET_PICK_UP_STORE:
            const { store } = action;

            return {
                ...state,
                store,
            };

        case StoreInPickUpActionType.CLEAR_PICK_UP_STORE:
            return {
                ...state,
                store: null,
            };

        default:
            return state;
    }
};

export default StoreInPickUpReducer;
