/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const CLEAR_CATEGORY = 'CLEAR_CATEGORY';
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';
export const UPDATE_CATEGORIES = 'UPDATE_CATEGORIES';
export const NO_CATEGORIES = 'NO_CATEGORIES';

/**
 * Update categories
 * @param categories
 * @returns {{categories: *, type: string}}
 * @namespace PlugAndSell/Blog/Store/Categories/Action/updateCategories */
export const updateCategories = (categories) => ({
    type: UPDATE_CATEGORIES,
    categories,
    isLoaded: true,
});

/**
 * Update categories
 * @param categories
 * @returns {{categories: *, type: string}}
 * @namespace PlugAndSell/Blog/Store/Categories/Action/updateCategory */
export const updateCategory = (category) => ({
    type: UPDATE_CATEGORY,
    category,
    isLoaded: true,
});

/**
 * Update categories
 * @param categories
 * @returns {{categories: *, type: string}}
 * @namespace PlugAndSell/Blog/Store/Categories/Action/clearCategory */
export const clearCategory = () => ({
    type: CLEAR_CATEGORY,
});

/**
 * @param message
 * @returns {{type: string, message: *}}
 * @namespace PlugAndSell/Blog/Store/Categories/Action/categoriesException */
export const categoriesException = (message) => ({
    type: NO_CATEGORIES,
    message,
});
