/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const UPDATE_POSTS_SEARCH = 'UPDATE_POSTS_SEARCH';
export const UPDATE_POSTS_SEARCH_LOAD_STATUS = 'UPDATE_POSTS_SEARCH_LOAD_STATUS';

/** @namespace PlugAndSell/Blog/Store/PostsSearch/Action/updatePostsSearch */
export const updatePostsSearch = (items) => ({
    type: UPDATE_POSTS_SEARCH,
    items,
});

/** @namespace PlugAndSell/Blog/Store/PostsSearch/Action/updatePostsLoadStatus */
export const updatePostsLoadStatus = (status) => ({
    type: UPDATE_POSTS_SEARCH_LOAD_STATUS,
    isLoading: status,
});
