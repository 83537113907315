import getStore from 'Util/Store';

export const GoogleTagManagerDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    '../store/GoogleTagManager/GoogleTagManager.dispatcher'
);

/** @namespace ScandipwaSocialshare/Plugin/RouterContainerPlugin/mapDispatchToProps */
export const mapDispatchToProps = (args, callback) => {
    const { dispatch } = getStore();
    GoogleTagManagerDispatcher.then(({ default: dispatcher }) => dispatcher.handleData(dispatch));

    return callback(...args);
};

export default {
    'PlugAndSell2/Component/Router/Container': {
        'member-function': { componentDidMount: mapDispatchToProps },
    },
};
