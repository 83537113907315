import { PureComponent } from 'react';

import { ReactElement } from 'Type/Common.type';
import { isEmpty } from 'Util/Common';
import { formatPrice } from 'Util/Price';

import { CheckoutSuccessTotalsProps } from './CheckoutSuccessTotals.type';

import './CheckoutSuccessTotals.style';

/** @namespace PlugAndSell2/Component/CheckoutSuccessTotals/Component */
export class CheckoutSuccessTotals extends PureComponent<CheckoutSuccessTotalsProps> {
    renderRow(label: string, value: string, options: { special?: boolean; type?: 'gross' | 'net' }): ReactElement {
        return (
            <div block="CheckoutSuccessTotals" elem="Row" mods={{ special: options.special }}>
                <dt>
                    {label}: {options.type && <span>({options.type})</span>}
                </dt>
                <dd>{value}</dd>
            </div>
        );
    }

    renderRows(): ReactElement {
        const { totals } = this.props;

        return (
            <dl block="CheckoutSuccessTotals" elem="Rows">
                {this.renderRow('Total', formatPrice(totals.subTotal?.value || NaN, totals.subTotal?.currency), {
                    type: 'gross',
                })}
                {this.renderRow('Shipping cost', formatPrice(totals.shippingGross?.value || NaN, totals.shippingGross?.currency), {
                    type: 'gross',
                })}
                {this.renderRow('Total', formatPrice(totals.grandTotal?.value || NaN, totals.grandTotal?.currency), {
                    special: true,
                    type: 'gross',
                })}
            </dl>
        );
    }

    render() {
        const { totals } = this.props;

        if (isEmpty(totals)) {
            return null;
        }

        return <div block="CheckoutSuccessTotals">{this.renderRows()}</div>;
    }
}

export default CheckoutSuccessTotals;
