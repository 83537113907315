import { PureComponent } from 'react';

import { formatPrice } from 'Util/Price';

import productPlaceholder from '../../assets/images/productPlaceholder.png';
import { CheckoutSuccessProductProps } from './CheckoutSuccessProduct.type';

import './CheckoutSuccessProduct.style';

/** @namespace PlugAndSell2/Component/CheckoutSuccessProduct/Component */
export class CheckoutSuccessProductComponent extends PureComponent<CheckoutSuccessProductProps> {
    render() {
        const { name, originalPrice, qty, rowTotal, currencyCode, image, unitPrice } = this.props;
        const isSpecial = unitPrice !== originalPrice;

        return (
            <div block="CheckoutSuccessProduct">
                <div block="CheckoutSuccessProduct" elem="Box">
                    <img src={image?.url ? image.url : productPlaceholder} alt={name} />
                    <div block="CheckoutSuccessProduct" elem="SubBox">
                        <p>{name}</p>
                    </div>
                </div>
                <div block="CheckoutSuccessProduct" elem="Box">
                    <p block="CheckoutSuccessProduct" elem="MobileProductTitle">
                        {__('Price per unit')}
                    </p>
                    {isSpecial && (
                        <p block="CheckoutSuccessProduct" elem="SpecialPrice" mods={{ specialPrice: !!isSpecial }}>
                            {formatPrice(unitPrice, currencyCode)}
                        </p>
                    )}
                    <p block="CheckoutSuccessProduct" elem="OriginalPrice" mods={{ isCrossed: !!isSpecial }}>
                        {formatPrice(originalPrice, currencyCode)}
                    </p>
                </div>
                <div block="CheckoutSuccessProduct" elem="Box">
                    <p block="CheckoutSuccessProduct" elem="MobileProductTitle">
                        {__('Qty')}
                    </p>

                    {qty}
                </div>
                <div block="CheckoutSuccessProduct" elem="Box">
                    <p block="CheckoutSuccessProduct" elem="MobileProductTitle">
                        {__('Cost')}
                    </p>

                    <p block="CheckoutSuccessProduct" elem="OriginalPrice">
                        {formatPrice(rowTotal, currencyCode)}
                    </p>
                </div>
            </div>
        );
    }
}

export default CheckoutSuccessProductComponent;
