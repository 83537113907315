/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { UPDATE_POSTS_SEARCH, UPDATE_POSTS_SEARCH_LOAD_STATUS } from './PostsSearch.action';

export const initialState = {
    postsInSearch: [],
    isLoading: true,
};

/** @namespace PlugAndSell/Blog/Store/PostsSearch/Reducer/PostsSearchReducer */
export const PostsSearchReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_POSTS_SEARCH:
            const { items } = action;

            return {
                ...state,
                postsInSearch: items,
            };

        case UPDATE_POSTS_SEARCH_LOAD_STATUS:
            const { isLoading } = action;

            return {
                ...state,
                isLoading,
            };

        default:
            return state;
    }
};

export default PostsSearchReducer;
