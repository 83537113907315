/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
import { lazy } from 'react';

export const MF_BLOG_INDEX = 'MF_BLOG_INDEX';
export const MF_BLOG_POST = 'MF_BLOG_POST';
export const MF_BLOG_CATEGORY = 'MF_BLOG_CATEGORY';
export const MF_BLOG_TAG = 'MF_BLOG_TAG';
export const MF_BLOG_AUTHOR = 'MF_BLOG_AUTHOR';

export const PostsListing = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "postsListing" */ '../route/PostsListing'));
export const PostsDetails = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "postsDetails" */ '../route/PostsDetails'));

const UrlRewritesComponent_renderContent = (args, callback, instance) => {
    const { props, type } = instance.props;

    switch (type) {
        case MF_BLOG_INDEX:
        case MF_BLOG_CATEGORY:
        case MF_BLOG_TAG:
        case MF_BLOG_AUTHOR: {
            return <PostsListing {...props} />;
        }
        case MF_BLOG_POST: {
            return <PostsDetails {...props} />;
        }
        default: {
            return callback(...args);
        }
    }
};

const UrlRewritesContainer_getTypeSpecificProps = (args, callback, instance) => {
    const {
        urlRewrite: { id },
    } = instance.props;

    switch (instance.getType()) {
        case MF_BLOG_AUTHOR: {
            return {
                authorId: id,
            };
        }
        case MF_BLOG_TAG: {
            return {
                tagId: id,
            };
        }
        case MF_BLOG_CATEGORY: {
            return {
                categoryId: id,
            };
        }
        case MF_BLOG_POST: {
            return {
                postId: id,
            };
        }
        default: {
            return callback(...args);
        }
    }
};

export default {
    'PlugAndSell2/Route/UrlRewrites/Component': {
        'member-function': {
            renderContent: UrlRewritesComponent_renderContent,
        },
    },
    'PlugAndSell2/Route/UrlRewrites/Container': {
        'member-function': {
            getTypeSpecificProps: UrlRewritesContainer_getTypeSpecificProps,
        },
    },
};
