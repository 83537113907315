import { UrlRewritePageType } from 'Route/UrlRewrites/UrlRewrites.config';
import { isSignedIn } from 'Util/Auth/IsSignedIn';

import { HOMEPAGE, LOGIN, LOGOUT, NOT_APPLICABLE, NOT_FOUND, ROOT } from '../component/GoogleTagManager/GoogleTagManager.config';
import { baseProductData } from './baseProduct.data';

/** @namespace GtmNew/EventData/General/Data/routes */
export const routes = () => ['cart', 'checkout', 'account', 'wishlist', 'forgot-password', 'contact', 'compare', 'blog', 'stores', 'search'];

/** @namespace GtmNew/EventData/General/Data/getStoreView */
export const getStoreView = (store) => store.ConfigReducer.code || '';

/** @namespace GtmNew/EventData/General/Data/getLocale */
export const getLocale = (store) => store.ConfigReducer.locale || '';

/** @namespace GtmNew/EventData/General/Data/getCustomerID */
export const getCustomerID = (store) => {
    if (isSignedIn()) {
        return { customerId: store.MyAccountReducer.customer.id || 0 };
    }

    return { customerId: NOT_APPLICABLE };
};

/** @namespace GtmNew/EventData/General/Data/getCustomerEmail */
export const getCustomerEmail = (store) => {
    if (isSignedIn()) {
        return { CustomerEmail: store.MyAccountReducer.customer.email || '' };
    }

    return null;
};

/** @namespace GtmNew/EventData/General/Data/getLoginState */
export const getLoginState = () => {
    if (isSignedIn()) {
        return { userLoginState: LOGIN };
    }

    return { userLoginState: LOGOUT };
};

/** @namespace GtmNew/EventData/General/Data/getCustomerOrderData */
export const getCustomerOrderData = (store) => {
    const { MyAccountReducer: { customer: { userExistingCustomer = NOT_APPLICABLE, userLifetimeValue = 0, userLifetimeOrders = 0 } = {} } = {} } =
        store;

    return { userExistingCustomer, userLifetimeValue, userLifetimeOrders };
};

/** @namespace GtmNew/EventData/General/Data/getPath */
export const getPath = (pathname = '/', storeView = '') => {
    if (pathname.includes(storeView)) {
        return pathname.replace(`/${storeView}`, '');
    }

    return pathname;
};

/** @namespace GtmNew/EventData/General/Data/getPageType */
export const getPageType = (store, pathname = window.location.pathname) => {
    const storeView = getStoreView(store);
    const path = getPath(pathname, storeView);

    // If user Lands on Homepage
    if (path === ROOT) {
        return { pageType: HOMEPAGE };
    }

    // If user Lands on one of React routes
    const page = routes().find((route) => {
        if (path.includes(route)) {
            return { pageType: route };
        }
    });

    if (page !== ROOT) {
        return { pageType: page };
    }

    // if one Rewrite
    const {
        urlRewrite: { type, notFound = false },
    } = store.UrlRewritesReducer;

    if (notFound) {
        return { pageType: NOT_FOUND };
    }

    if (type === UrlRewritePageType.TYPE_CMS_PAGE) {
        return { pageType: `cmspage${path}` };
    }

    if (type === UrlRewritePageType.TYPE_CATEGORY) {
        return { pageType: UrlRewritePageType.TYPE_CATEGORY.toLowerCase() };
    }

    if (type === UrlRewritePageType.TYPE_PRODUCT) {
        return { pageType: UrlRewritePageType.TYPE_PRODUCT.toLowerCase() };
    }

    if (type) {
        return { pageType: type.toLowerCase() };
    }

    return { pageType: '' };
};

/** @namespace GtmNew/EventData/General/Data/getProducts */
export const getProducts = (items, event = '') => {
    // eslint-disable-next-line no-undef
    const fields = [];

    items.map((item) => {
        fields.push(baseProductData(item, false, false, event));
    });

    return fields;
};

/** @namespace GtmNew/EventData/General/Data/getCart */
export const getCart = (store) => {
    const {
        cartTotals: { items = [] },
    } = store.CartReducer;

    if (items.length) {
        return {
            cart: getProducts(items),
        };
    }
};

// General Event Data
/** @namespace GtmNew/EventData/General/Data/getGeneralEventData */
export const getGeneralEventData = (store) => {
    if (store) {
        return {
            storeView: getStoreView(store),
            language: getLocale(store),
            ...getLoginState(),
            ...getCustomerID(store),
            ...getCustomerEmail(store),
            ...getCustomerOrderData(store),
            ...getPageType(store),
            ...getCart(store),
        };
    }

    return null;
};
