import { AnyAction } from 'redux';
import { AccountManagers } from "../../query/AccountManager.type"


export enum AccountManagerActionType {
  UPDATE_ACCOUNT_MANAGER = 'UPDATE_ACCOUNT_MANAGER',
}

export interface AccountManagerStore {
  accountManagers: AccountManagers | {};
}

declare module 'Util/Store/Store.type' {
  export interface RootState {
    AccountManagerReducer: AccountManagerStore;
  }
}

export type AccountManagerDispatcherData = {
  accountManager: AccountManagers
}

export interface UpdateAccountManagerAction extends AnyAction {
  type: AccountManagerActionType.UPDATE_ACCOUNT_MANAGER;
  payload: AccountManagers;
}