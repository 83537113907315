/* eslint-disable @scandipwa/scandipwa-guidelines/no-arrow-functions-in-class */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import AuthorReducer from '../store/Author/Author.reducer';
import CategoriesReducer from '../store/Categories/Categories.reducer';
import PostsReducer from '../store/Posts/Posts.reducer';
import PostsDetailsReducer from '../store/PostsDetails/PostsDetails.reducer';
import PostsSearchReducer from '../store/PostsSearch/PostsSearch.reducer';
import TagsReducer from '../store/Tags/Tags.reducer';

export const aroundGetStaticReducers = (args, callback) => ({
    ...callback(...args),
    PostsDetailsReducer,
    PostsReducer,
    PostsSearchReducer,
    CategoriesReducer,
    TagsReducer,
    AuthorReducer,
});

export default {
    'PlugAndSell2/Store/Index/getStaticReducers': {
        function: aroundGetStaticReducers,
    },
};
