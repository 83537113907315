import { Reducer } from 'redux';

import { ContactFormActionType, ContactFormStore, UpdateContactFormAction } from './ContactForm.type';

export const initialState = {
    isLoading: false,
};

/** @namespace PlugAndSell2/Store/ContactForm/Reducer/ContactFormReducer */
export const ContactFormReducer: Reducer<ContactFormStore, UpdateContactFormAction> = (state = initialState, action) => {
    const { type, data } = action;

    switch (type) {
        case ContactFormActionType.UPDATE_CONTACT_FORM:
            return { ...state, ...data };

        default:
            return state;
    }
};

export default ContactFormReducer;
