import { RouterItemType } from 'Component/Router/Router.config';

import Announcement from '../component/Announcement';
const beforeItemsType = (original) => [
    ...original,
    {
        component: <Announcement />,
        position: 40,
        name: 'announcement',
    },
];

export default {
    'PlugAndSell2/Component/Router/Component': {
        'member-property': {
            [RouterItemType.BEFORE_ITEMS_TYPE]: beforeItemsType,
        },
    },
};
