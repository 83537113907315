import { AccountManagerReducer } from "../store/AccountManager";

export const aroundGetStaticReducers = (args: any, callback: Function) => { 
  return ({
    ...callback(...args),
    AccountManagerReducer,
})};

export default {
    'PlugAndSell2/Store/Index/getStaticReducers': {
        function: aroundGetStaticReducers,
    },
};
