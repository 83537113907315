import { Reducer } from 'redux';

import { OfflineAction, OfflineActionType, OfflineStore } from './Offline.type';

/** @namespace PlugAndSell2/Store/Offline/Reducer/getInitialState */
export const getInitialState = (): OfflineStore => ({
    isOffline: true,
    isBig: false,
});

/** @namespace PlugAndSell2/Store/Offline/Reducer/OfflineReducer */
export const OfflineReducer: Reducer<OfflineStore, OfflineAction> = (state = getInitialState(), action) => {
    switch (action.type) {
        case OfflineActionType.SHOW_OFFLINE_NOTICE:
            const { isOffline } = action;

            return {
                ...state,
                isOffline,
            };
        case OfflineActionType.SET_BIG_OFFLINE_NOTICE:
            const { isBig } = action;

            return {
                ...state,
                isBig,
            };
        default:
            return state;
    }
};

export default OfflineReducer;
