import { AccountManagerActionType, AccountManagerStore, UpdateAccountManagerAction } from "./AccountManager.type";
import { Reducer } from 'redux';

export const initialState: AccountManagerStore = {
    accountManagers: {}
};

/** @namespace PlugAndSell/Blog/Store/PostsSearch/Reducer/AccountManagerReducer */
export const AccountManagerReducer: Reducer<AccountManagerStore, UpdateAccountManagerAction>  = (state = initialState, action) => {

    switch(action.type) {
      case AccountManagerActionType.UPDATE_ACCOUNT_MANAGER:
        return ({ ...state, accountManagers: action.payload })
      default:
        return state
    }
};

export default AccountManagerReducer;
