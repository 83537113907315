import { cloneElement, createElement } from 'react';

import NoMatch from 'Route/NoMatch';

import Check from '../component/Check';

import { RouterItemType } from "Component/Router/Router.config";

const RouterComponent_getSortedItems = (args, callback) => callback(...args).map(({
    component, permissions = [], themes = [], ...rest
}) => {
    const [type] = args;

    if (type !== RouterItemType.SWITCH_ITEMS_TYPE) {
        return {
            ...rest,
            component: createElement(Check, { permissions, themes }, cloneElement(component))
        };
    }

    return {
        ...rest,
        component: cloneElement(component, {
            render: (props) => createElement(
                Check,
                { permissions, themes, fallback: createElement(NoMatch) },
                component.props.render(props)
            )
        })
    };
});

export default {
    'PlugAndSell2/Component/Router/Component': {
        'member-function': {
            getSortedItems: RouterComponent_getSortedItems
        }
    }
};
