import { IndexedProduct } from "Util/Product/Product.type";
import getStore from "Util/Store";

const appendImageToInitialState = (args: any[], callback: Function) => {
  return {
    ...callback(...args),
    image: '',
  };
};

const appendImageToProductMeta = (args: [product: IndexedProduct], callback: Function) => {
  const ctxTags = callback(...args);
  const { getState } = getStore()
  const { ProductReducer: { product } } = getState()

  const image = product?.small_image?.url;

  return { ...ctxTags, image };
};

export default {
  'PlugAndSell2/Store/Meta/Reducer/getInitialState': {
      function: appendImageToInitialState,
  },
  'PlugAndSell2/Store/Meta/Dispatcher': {
    'member-function': {
      _getProductMeta: appendImageToProductMeta,
    },
  },
};
