import { ContractorsReducer } from "../store/Contractors/Contractors.reducer";

export const aroundCreateContractorReducer = (args: any, callback: Function) => { 
  return ({
    ...callback(...args),
    ContractorsReducer
  })
};

export default {
    'PlugAndSell2/Store/Index/getStaticReducers': {
        function: aroundCreateContractorReducer,
    },
};
