import { SetIsUrlRewritesLoadingAction, UpdateUrlRewriteAction, UrlRewrite, UrlRewritesActionType } from './UrlRewrites.type';

/** @namespace PlugAndSell2/Store/UrlRewrites/Action/updateUrlRewrite */
export const updateUrlRewrite = (urlRewrite: UrlRewrite, requestedUrl: string): UpdateUrlRewriteAction => ({
    type: UrlRewritesActionType.UPDATE_URL_REWRITE,
    urlRewrite,
    requestedUrl,
});

/** @namespace PlugAndSell2/Store/UrlRewrites/Action/setIsUrlRewritesLoading */
export const setIsUrlRewritesLoading = (isLoading: boolean): SetIsUrlRewritesLoadingAction => ({
    type: UrlRewritesActionType.IS_LOADING_URL_REWRITE,
    isLoading,
});
