import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import CartDispatcher from 'Store/Cart/Cart.dispatcher';
import { isSignedIn } from 'Util/Auth/IsSignedIn';
import { getAuthorizationToken } from 'Util/Auth/Token';
import { RootState } from 'Util/Store/Store.type';

import MakeCommercePaymentStatus from './MakeCommercePaymentStatus.component';
import {
    MakeCommercePaymentStatusComponentProps,
    MakeCommercePaymentStatusContainerProps,
    MakeCommercePaymentStatusContainerPropsKeys,
    MakeCommercePaymentStatusContainerState,
} from './MakeCommercePaymentStatus.type';

/** @namespace PlugAndSell2/Route/MakeCommercePaymentStatus/Container/mapStateToProps */
export const mapStateToProps = (state: RootState) => ({
    isCartLoading: state.CartReducer.isLoading,
});

/** @namespace PlugAndSell2/Route/MakeCommercePaymentStatus/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch: Dispatch) => ({
    updateInitialCartData: () => CartDispatcher.updateInitialCartData(dispatch, !!getAuthorizationToken(), false, true),
});

/** @namespace PlugAndSell2/Route/MakeCommercePaymentStatus/Container */
export class MakeCommercePaymentStatusContainer extends PureComponent<
    MakeCommercePaymentStatusContainerProps,
    MakeCommercePaymentStatusContainerState
> {
    state = {
        reloadedCart: false,
    };

    componentDidUpdate(prevProps: Readonly<MakeCommercePaymentStatusContainerProps>): void {
        const { isCartLoading, updateInitialCartData } = this.props;
        const { reloadedCart } = this.state;

        if (isSignedIn() && prevProps.isCartLoading && !isCartLoading && !reloadedCart) {
            // Monkey patch to update (clear) cart for signed in users
            updateInitialCartData();
            this.setState({ reloadedCart: true });
        }
    }

    containerProps(): Pick<MakeCommercePaymentStatusComponentProps, MakeCommercePaymentStatusContainerPropsKeys> {
        const orderId = localStorage.getItem('last_order_id') || '';

        return {
            orderId,
        };
    }

    render() {
        return <MakeCommercePaymentStatus {...this.containerProps()} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MakeCommercePaymentStatusContainer);
