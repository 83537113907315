import { withStoreRegex } from "Component/Router/Router.component";
import { RouterItemType } from "Component/Router/Router.config";
import { RouterItem } from "Component/Router/Router.type";

import MyAccount from "Route/MyAccount";

import { Route } from "react-router-dom";
import { RMA_AVAILABLE_RETURNS_ROUTE, RMA_AVAILABLE_RETURNS_TAB_ID, RMA_MY_RETURNS_ROUTE, RMA_MY_RETURNS_TAB_ID } from "./MyAccount.plugin";
import { RMA_AVAILABLE_RETURNS_ORDER } from "../components/RmaMyReturns/RmaMyReturns.config";

export const switchItemsProperty = (originalMember: RouterItem[]) => {
  return [
    ...originalMember,
    {
      component: (
          <Route
              path={withStoreRegex(RMA_AVAILABLE_RETURNS_ROUTE)}
              render={({ match }) => {
                  return <MyAccount match={match} selectedTab={RMA_AVAILABLE_RETURNS_TAB_ID} />;
              }}
          />
      ),
      position: 700,
      name: 'rma-available-returns',
    },
    {
      component: (
          <Route
              path={withStoreRegex(RMA_MY_RETURNS_ROUTE)}
              render={({ match }) => {
                  return <MyAccount match={match} selectedTab={RMA_MY_RETURNS_TAB_ID} />;
              }}
          />
      ),
      position: 701,
      name: 'rma-my-returns',
    },
  ];
}

export default {
  'PlugAndSell2/Component/Router/Component': {
    'member-property': {
      [RouterItemType.SWITCH_ITEMS_TYPE]: switchItemsProperty,
    }
  }
}