/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { CLEAR_CATEGORY, UPDATE_CATEGORIES, UPDATE_CATEGORY } from './Categories.action';

export const emptyCategories = {
    items: [],
    count: 0,
};

/** @namespace PlugAndSell/Blog/Store/Categories/Reducer/getInitialState */
export const getInitialState = () => ({
    category: null,
    categories: emptyCategories,
});

/** @namespace PlugAndSell/Blog/Store/Categories/Reducer/CategoriesReducer */
export const CategoriesReducer = (state = getInitialState(), action) => {
    const { isLoaded, type } = action;

    switch (type) {
        case UPDATE_CATEGORIES:
            const {
                categories: { items, total_count: count },
            } = action;

            return {
                ...state,
                isLoaded,
                categories: {
                    items,
                    count,
                },
            };
        case UPDATE_CATEGORY:
            const { category } = action;

            return {
                ...state,
                isLoaded,
                category,
            };
        case CLEAR_CATEGORY:
            return {
                ...state,
                category: null,
            };
        default:
            return state;
    }
};

export default CategoriesReducer;
