import { Fragment, PureComponent, ReactPortal } from 'react';
import { createPortal } from 'react-dom';

import { ReactElement } from 'Type/Common.type';

import { MetaComponentProps } from './Meta.type';

/**
 * Page Meta data
 * @class Meta
 * @namespace PlugAndSell2/Component/Meta/Component */
export class MetaComponent extends PureComponent<MetaComponentProps> {
    static defaultProps: Partial<MetaComponentProps> = {
        title: '',
        canonical_url: '',
    };

    componentDidMount(): void {
        // Remove prerendered meta tags so dynamic meta tags can take effect
        Array.prototype.slice.call(document.head.querySelectorAll('title[data-prerendered], meta[data-prerendered]')).forEach((tag) => {
            document.head.removeChild(tag);
        });
    }

    renderTitle(): ReactElement {
        const { default_title, title_prefix, title_suffix, title } = this.props;

        const titlePrefix = title_prefix ? `${title_prefix} | ` : '';
        const titleSuffix = title_suffix ? ` | ${title_suffix}` : '';

        return <title>{`${titlePrefix}${title || default_title}${titleSuffix}`}</title>;
    }

    renderCanonical(): ReactElement {
        const { canonical_url } = this.props;

        if (!canonical_url) {
            return null;
        }

        return <link rel="canonical" href={canonical_url} />;
    }

    renderMeta(): ReactElement {
        const { metadata } = this.props;

        return [
            <Fragment key="meta-tags">
                {this.renderTitle()}
                {this.renderCanonical()}
                {metadata.map((tag) => {
                    const { name, property, content } = tag;

                    return <meta key={name} name={name} property={property} content={content} />;
                })}
            </Fragment>,
        ];
    }

    render(): ReactPortal {
        return createPortal(this.renderMeta(), document.head);
    }
}

export default MetaComponent;
