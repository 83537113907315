import { withStoreRegex } from "Component/Router/Router.component";
import { RouterItemType } from "Component/Router/Router.config";
import { RouterItem } from "Component/Router/Router.type";
import MyAccount from "Route/MyAccount";
import { Route } from "react-router-dom";
import { CONTRACTOR_DOCS_TAB_ID } from "./MyAccount.plugin";

export const switchItemsProperty = (originalMember: RouterItem[]) => {
  return [
    ...originalMember,
    {
      component: (
          <Route
              path={withStoreRegex('/contractor-docs')}
              render={({ match }) => {
                  return <MyAccount match={match} selectedTab={CONTRACTOR_DOCS_TAB_ID} />;
              }}
          />
      ),
      position: 700,
      name: 'contractor-docs',
    },
  ];
}

export default {
  'PlugAndSell2/Component/Router/Component': {
    'member-property': {
      [RouterItemType.SWITCH_ITEMS_TYPE]: switchItemsProperty,
    }
  }
}