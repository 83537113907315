import { Reducer } from 'redux';

import { NoMatchActionType, NoMatchStore, UpdateNoMatchAction } from './NoMatch.type';

/** @namespace PlugAndSell2/Store/NoMatch/Reducer/getInitialState */
export const getInitialState = (): NoMatchStore => ({
    noMatch: false,
});

/** @namespace PlugAndSell2/Store/NoMatch/Reducer/NoMatchReducer */
export const NoMatchReducer: Reducer<NoMatchStore, UpdateNoMatchAction> = (state = getInitialState(), action) => {
    switch (action.type) {
        case NoMatchActionType.UPDATE_NOMATCH:
            const { noMatch } = action;

            return { noMatch };

        default:
            return state;
    }
};

export default NoMatchReducer;
