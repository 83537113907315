import { Reducer } from 'redux';

import { CategoryActionType, CategoryStore, UpdateCurrentCategoryAction } from './Category.type';

/** @namespace PlugAndSell2/Store/Category/Reducer/getInitialState */
export const getInitialState = (): CategoryStore => ({
    category: {},
});

/** @namespace PlugAndSell2/Store/Category/Reducer/CategoryReducer */
export const CategoryReducer: Reducer<CategoryStore, UpdateCurrentCategoryAction> = (state = getInitialState(), { type, category }) => {
    switch (type) {
        case CategoryActionType.UPDATE_CURRENT_CATEGORY:
            return {
                ...state,
                category: { ...category },
            };

        default:
            return state;
    }
};

export default CategoryReducer;
