import { Reducer } from 'redux';

import { OverlayActionType } from 'Store/Overlay/Overlay.type';

import { PopupActionType, PopupStore, PopupType } from './Popup.type';

/** @namespace PlugAndSell2/Store/Popup/Reducer/getInitialState */
export const getInitialState = (): PopupStore => ({
    popupPayload: {},
    shouldPopupClose: false,
    payload: {},
});

/** @namespace PlugAndSell2/Store/Popup/Reducer/PopupReducer */
export const PopupReducer: Reducer<PopupStore, PopupType> = (state = getInitialState(), action) => {
    const { payload, type } = action;

    switch (type) {
        case PopupActionType.SHOW_POPUP:
            return { ...state, popupPayload: payload };
        case OverlayActionType.HIDE_ACTIVE_OVERLAY:
            return { ...state, popupPayload: {} };
        case OverlayActionType.HIDE_ACTIVE_POPUP:
            return { ...state, shouldPopupClose: payload };
        default:
            return state;
    }
};

export default PopupReducer;
