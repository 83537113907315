import { MY_WISHLIST, SEARCH, WISHLIST } from '../component/GoogleTagManager/GoogleTagManager.config';
import { getPageType } from './general.data';

/** @namespace GtmNew/EventData/ActionField/Data/getActionField */
export const getActionField = (store, categoryId = 0, path = '', search = false) => {
    if (search || path.includes(SEARCH)) {
        return {
            list: SEARCH,
        };
    }

    if (path.includes(MY_WISHLIST)) {
        return { list: WISHLIST };
    }

    if (categoryId) {
        const {
            category: { name = '' },
        } = store.CategoryReducer;

        return {
            list: name,
        };
    }

    const { pageType } = getPageType(store, path);

    return {
        list: pageType,
    };
};
