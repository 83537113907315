/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { CLEAR_TAG, UPDATE_TAG, UPDATE_TAGS } from './Tags.action';

export const emptyTags = {
    items: [],
    count: 0,
};

/** @namespace PlugAndSell/Blog/Store/Tags/Reducer/getInitialState */
export const getInitialState = () => ({
    tag: null,
    tags: emptyTags,
    isLoaded: false,
});

/** @namespace PlugAndSell/Blog/Store/Tags/Reducer/TagsReducer */
export const TagsReducer = (state = getInitialState(), action) => {
    const { isLoaded, type } = action;

    switch (type) {
        case UPDATE_TAGS:
            const {
                tags: { items, total_count: count },
            } = action;

            return {
                ...state,
                isLoaded,
                tags: {
                    items,
                    count,
                },
            };

        case UPDATE_TAG:
            const { tag } = action;

            return {
                ...state,
                isLoaded,
                tag,
            };

        case CLEAR_TAG:
            return {
                ...state,
                tag: null,
            };
        default:
            return state;
    }
};

export default TagsReducer;
